import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSignOut from 'react-auth-kit/hooks/useSignOut';

const Logout: React.FC = () => {
    const navigate = useNavigate();
    const signOut = useSignOut();

    useEffect(() => {
        signOut();

        navigate('/login');
    }, [navigate, signOut]);

    return null;
};

export default Logout;
