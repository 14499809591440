/**
 * Helper function to get the value from localStorage
 * @param key
 * @param defaultValue
 */
const getStorageValue = (key: string, defaultValue: string): string => {
    const saved = localStorage.getItem(key);
    return saved !== null && saved !== '' ? saved : defaultValue;
};

/**
 * Custom hook to manage localStorage state
 * @param key
 * @param defaultValue
 * @param stringify
 */
export const useLocalStorage = (
    key: string,
    defaultValue: string,
    stringify: boolean = false
): [string, (newValue: string) => void] => {
    let storedValue = getStorageValue(key, defaultValue);
    storedValue = stringify ? JSON.stringify(storedValue) : storedValue;

    const setValue = (newValue: string) => {
        newValue = stringify ? JSON.stringify(newValue) : newValue;
        storedValue = newValue;

        localStorage.setItem(key, newValue);
    };

    return [storedValue, setValue];
};
