import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import { MantineProvider } from '@mantine/core';
import { AppRoutes } from './routes';
import { LanguageProvider } from './provider/LanguageProvider';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { authConfig } from './config';
import { theme } from './theme';
import store from './store';

import '@mantine/core/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';

const authStore = createStore(authConfig);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <LanguageProvider>
                <MantineProvider theme={theme}>
                    <AuthProvider store={authStore}>
                        <ToastContainer />
                        <AppRoutes />
                    </AuthProvider>
                </MantineProvider>
            </LanguageProvider>
        </Provider>
    </React.StrictMode>,
);
