import { createTheme, MantineColorsTuple } from '@mantine/core';

const myColor: MantineColorsTuple = [
    '#e1fefc',
    '#d2f7f4',
    '#acebe5',
    '#82dfd7',
    '#5fd5ca',
    '#48cfc3',
    '#37ccbf',
    '#23b5a8',
    '#0da195',
    '#008b81'
];

export const theme = createTheme({
    colors: {
        myColor,
    }
});
