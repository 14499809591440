import React from 'react';
import { AppShell, Burger, Flex, Group, NavLink, Image } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { WrapperProps } from '../../../types/WrapperTypes';

import LanguageSwitcher from '../../language/LanguageSelector';
import NavbarMinimalColored from './Navbar';

import styles from './Wrapper.module.css';

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
    const [opened, { toggle }] = useDisclosure();

    // noinspection JSVoidFunctionReturnValueUsed
    return (
        <AppShell
            header={{ height: 100 }}
            navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
            padding="md"
        >
            <AppShell.Header className={styles.header}>
                <Group h="100%" px="md">
                    <Flex justify="space-between" w='100%' align="center">
                        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                        <Image src="/assets/froxlor_manager_logo.png" h="4rem" w="100%" />
                        <LanguageSwitcher />
                    </Flex>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar>
                <NavbarMinimalColored />
            </AppShell.Navbar>
            <AppShell.Main>
                {children}
            </AppShell.Main>
        </AppShell>
    );
};

export default Wrapper;
