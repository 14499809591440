import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, Code, Center } from '@mantine/core';
import {
    IconBellRinging,
    IconFingerprint,
    IconKey,
    IconSettings,
    Icon2fa,
    IconDatabaseImport,
    IconReceipt2,
    IconSwitchHorizontal,
    IconLogout,
    IconServer2,
    IconUserCircle,
    IconUser,
    IconLayoutDashboard, IconHelp, IconAlignBoxLeftTop
} from '@tabler/icons-react';
import { NavBarItems } from '../../../types/NavigationType';
import { useDispatch, useSelector } from 'react-redux';
import { getTextString } from '../../../services/TextStringService';
import { loadTextStrings } from '../../../actions/TextStringActions';

import styles from './Navbar.module.css';

const NavbarMinimalColored = () => {
    // const dispatch = useDispatch();
    const [active, setActive] = useState(2);
    const navigate = useNavigate();
    // @ts-ignore
    // const { loading, error } = useSelector((state) => state.textStringsReducer);

    // useEffect(() => {
    //     // @ts-ignore
    //     dispatch(loadTextStrings());
    // }, [dispatch]);

    // if (loading) {
    //     return <div>Loading...</div>;
    // }
    //
    // if (error) {
    //     return <div>Error loading text strings</div>;
    // }

    const textStringsPrepared = {
        account: useSelector(() =>
            getTextString('text_string_navigation_account'),
        ),
        profile: useSelector(() =>
            getTextString('text_string_navigation_account'),
        ),
        logout: useSelector(() => getTextString('text_string_button_logout')),
        support: useSelector(() =>
            getTextString('text_string_navigation_support'),
        ),
        settings: useSelector(() =>
            getTextString('text_string_navigation_settings'),
        ),
        server: useSelector(() =>
            getTextString('text_string_navigation_server'),
        ),
        dashboard: useSelector(() =>
            getTextString('text_string_navigation_dashboard'),
        ),
        ssh: useSelector(() =>
            getTextString('text_string_navigation_ssh_keyys'),
        ),
        subscription: useSelector(() =>
            getTextString('text_string_navigation_subscription'),
        ),
    };

    const data: NavBarItems[] = [
        {
            link: '/',
            label: textStringsPrepared.dashboard,
            icon: IconLayoutDashboard,
        },
        {
            link: '/server',
            label: textStringsPrepared.server,
            icon: IconServer2,
        },
        {
            link: '/ssh',
            label: textStringsPrepared.ssh,
            icon: IconKey,
        },
        {
            label: textStringsPrepared.account,
            category: 'account',
            icon: IconUserCircle,
            views: [
                {
                    link: '/account',
                    label: textStringsPrepared.account,
                    icon: IconUser,
                },
            ],
        },
        {
            link: '/subscription',
            label: textStringsPrepared.subscription,
            icon: IconAlignBoxLeftTop,
        },
        {
            link: '/settings',
            label: textStringsPrepared.settings,
            icon: IconSettings,
        },
    ];

    const links = data.map((item) => (
        <a
            className={styles.link}
            // @ts-ignore
            data-active={item.label === active || undefined}
            key={item.label}
            onClick={(event) => {
                event.preventDefault();
                // @ts-ignore
                setActive(item.label);
                // @ts-ignore
                navigate(item.link);
            }}
        >
            <item.icon className={styles.linkIcon} stroke={1.5} />
            <span>{item.label}</span>
        </a>
    ));

    return (
        <nav className={styles.navbar}>
            <div className={styles.navbarMain}>{links}</div>

            <div className={styles.footer}>
                <a
                    className={styles.link}
                    onClick={(event) => {
                        event.preventDefault();
                        setActive(textStringsPrepared.support);
                        navigate('/support');
                    }}
                >
                    <IconHelp
                        className={styles.linkIcon}
                        stroke={1.5}
                    />
                    <span>{textStringsPrepared.support}</span>
                </a>

                <a
                    className={styles.link}
                    onClick={(event) => {
                        event.preventDefault();
                        setActive(textStringsPrepared.logout);
                        navigate('/logout');
                    }}
                >
                    <IconLogout className={styles.linkIcon} stroke={1.5} />
                    <span>{textStringsPrepared.logout}</span>
                </a>
                <Center mt="1rem">
                    <Code fw={700} className={styles.version}>
                        v0.1.0
                    </Code>
                </Center>
            </div>
        </nav>
    );
};

export default NavbarMinimalColored;
