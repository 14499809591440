import React from 'react';

const Support: React.FC = () => {
    return (
        <div>
            Support
        </div>
    );
};

export default Support;
