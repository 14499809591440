const initialState = {
    loading: true,
    textStrings: {},
};

const textStringsReducer = (state = initialState, action: any) => {
    console.log('action', action);

    switch (action.type) {
        case 'SET_TEXT_STRINGS':
            return { ...state, textStrings: action.payload };
        case 'TEXTSTRINGS_LOADING':
            return { ...state, loading: true, error: null };
        case 'TEXTSTRINGS_LOADED':
            return { ...state, loading: false, textStrings: action.payload };
        case 'TEXTSTRINGS_ERROR':
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default textStringsReducer;
