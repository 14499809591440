const initialState = {
    currentLanguage: 'en'
};

const languageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return { ...state, currentLanguage: action.payload };
        default:
            return state;
    }
};

export default languageReducer;
