import { Bounce } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

export const toastOptions: ToastOptions = {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
    transition: Bounce,
};

export const authConfig: {
    authName: string;
    authType: 'localstorage' | 'cookie';
    cookieDomain: string;
    cookieSecure: boolean;
} = {
    authName: '_auth',
    authType: 'localstorage',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
};

export const apiUrl: string = 'https://fmm.seawebarts.de/api';
