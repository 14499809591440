import store from '../store';
import { TextStrings } from '../types/TextStrings';

export const textStringsRepository: TextStrings = {
    text_string_button_login: {
        de: 'Einloggen',
        en: 'Login',
    },
    text_string_button_logout: {
        de: 'Abmelden',
        en: 'Logout',
    },
    text_string_text_login_loading: {
        de: 'Login läuft',
        en: 'Loggin in',
    },
    text_string_text_login_success: {
        de: 'Login erfolgreich',
        en: 'Login success',
    },
    text_string_text_login_failed: {
        de: 'Fehler',
        en: 'Login failed',
    },
    text_string_text_login_please_wait: {
        de: 'Bitte warten...',
        en: 'Please wait...',
    },
    text_string_text_login_redirect_dashboard: {
        de: 'Leite um zum Dashboard...',
        en: 'Redirects to Dashboard...',
    },
    text_string_text_login_check_credentials: {
        de: 'Überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut.',
        en: 'PLease check your credentials and try again',
    },
    text_string_navigation_account: {
        de: 'Konto',
        en: 'Account',
    },
    text_string_navigation_profile: {
        de: 'Profil',
        en: 'Profile',
    },
    text_string_navigation_support: {
        de: 'Support',
        en: 'Support',
    },
    text_string_navigation_settings: {
        de: 'Einstellungen',
        en: 'Settings',
    },
    text_string_navigation_server: {
        de: 'Server',
        en: 'Server',
    },
    text_string_navigation_dashboard: {
        de: 'Dashboard',
        en: 'Dashboard',
    },
    text_string_navigation_ssh_keyys: {
        de: 'SSH-Schlüssel',
        en: 'SSH-Keys',
    },
    text_string_navigation_subscription: {
        de: 'Abonnement',
        en: 'Subscription',
    },
    text_string_language_english: {
        de: 'Englisch',
        en: 'English',
    },
    text_string_language_german: {
        de: 'Deutsch',
        en: 'German',
    },
};

/**
 * @param textName
 */
export const getTextString = (textName: string) => {
    const { textStrings } = store.getState().textStrings;
    const { currentLanguage } = store.getState().language;
    const textString = textStrings[textName];

    if (!textString) {
        return '';
    }

    return textString[currentLanguage] || textString['en'] || '';
};

export const TextStringService = async () => {
    return new Promise((resolve) => {
        resolve(textStringsRepository);
    });
};
