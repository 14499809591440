import { configureStore } from '@reduxjs/toolkit';
import textStringsReducer from './reducers/TextStringReducer';
import languageReducer from './reducers/LanguageReducer';

const store = configureStore({
    reducer: {
        textStrings: textStringsReducer,
        language: languageReducer,
    },
});

export default store;
