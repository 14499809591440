import React from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { jwtDecode } from "jwt-decode";

const Dashboard: React.FC = () => {
    const [token, setToken] = useLocalStorage('_auth', '');

    if (token) {
        const decoded: any = jwtDecode(token);
        console.log(decoded);
    }

    return (
        <div>
            <h1>Hello Dashboard</h1>
        </div>
    );
};

export default Dashboard;
