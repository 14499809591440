import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrl, toastOptions } from '../config';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import axios, { AxiosResponse } from 'axios';

import { Button, TextInput, Flex, Title, Box } from '@mantine/core';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getTextString } from '../services/TextStringService';
import { LoginResponse } from '../types/RequestTypes';

const Login: React.FC<any> = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const signIn = useSignIn();
    const navigate = useNavigate();

    const textStrings = {
        success: useSelector(() =>
            getTextString('text_string_text_login_success'),
        ),
        failed: useSelector(() =>
            getTextString('text_string_text_login_success'),
        ),
        buttonText: useSelector(() =>
            getTextString('text_string_button_login'),
        ),
    };

    const login = (): Promise<AxiosResponse<LoginResponse>> => {
        return axios.post<LoginResponse>(`${apiUrl}/users/login`, formData);
    };

    const onSubmit = async () => {
        const loginPromise = login();
        const response = await loginPromise;

        const config = {
            auth: {
                token: response.data.token,
            },
            userState: response.data.email,
        };
        const signedIn = signIn(config);

        if (signedIn) {
            toast(textStrings.success, toastOptions);
            navigate('/');
        } else {
            toast.error(textStrings.failed);
        }
    };

    return (
        <Flex
            mih="100vh"
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
            w="100%"
        >
            <Box>
                <Title order={1} size="md" mb="4">
                    Froxlor Manager Login
                </Title>
                <TextInput
                    type="email"
                    variant="outline"
                    onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                    }
                    placeholder="Email"
                    mb="4"
                />
                <TextInput
                    type="password"
                    variant="outline"
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            password: e.target.value,
                        })
                    }
                    placeholder="Password"
                    mb="4"
                />
                <Button type="submit" mb="4" onClick={onSubmit}>
                    {textStrings.buttonText}
                </Button>
            </Box>
        </Flex>
    );
};

export default Login;
