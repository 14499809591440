// actions/textStringsActions.js
import { TextStringService } from '../services/TextStringService';

export const setTextStrings = (textStrings: object) => ({
    type: 'SET_TEXT_STRINGS',
    payload: textStrings,
});

// @ts-ignore
export const loadTextStrings = () => async (dispatch) => {
    dispatch({ type: 'TEXTSTRINGS_LOADING' });

    try {
        const strings = await TextStringService();
        dispatch({ type: 'TEXTSTRINGS_LOADED', payload: strings });
    } catch (error) {
        dispatch({ type: 'TEXTSTRINGS_ERROR', error });
    }
};
