import React, { useEffect } from 'react';
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTextStrings } from './actions/TextStringActions';
import { textStringsRepository } from './services/TextStringService';

import Login from './views/Login';
import NotFound from './views/NotFound';
import Logout from './views/Logout';
import Dashboard from './views/Dashboard';
import Account from './views/Account';
import Wrapper from './components/ui/content/Wrapper';
import Server from './views/Server';
import Support from './views/Support';

export const AppRoutes: React.FC = () => {
    const fallbackPath: string = '/login';

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTextStrings(textStringsRepository));
    }, [dispatch]);

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route
                    path={'/'}
                    element={
                        <RequireAuth fallbackPath={fallbackPath}>
                            <Wrapper>
                                <Dashboard />
                            </Wrapper>
                        </RequireAuth>
                    }
                />
                <Route
                    path={'/server'}
                    element={
                        <RequireAuth fallbackPath={fallbackPath}>
                            <Wrapper>
                                <Server />
                            </Wrapper>
                        </RequireAuth>
                    }
                />
                <Route
                    path={'/account'}
                    element={
                        <RequireAuth fallbackPath={fallbackPath}>
                            <Wrapper>
                                <Account />
                            </Wrapper>
                        </RequireAuth>
                    }
                />
                <Route
                    path={'/support'}
                    element={
                        <RequireAuth fallbackPath={fallbackPath}>
                            <Wrapper>
                                <Support />
                            </Wrapper>
                        </RequireAuth>
                    }
                />
                <Route path="/logout" element={<Logout />}></Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};
