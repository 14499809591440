import React, { createContext, useContext, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../actions/LanguageActions';

// @ts-ignore
export const LanguageContext = createContext();

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useLocalStorage('language', navigator.language.slice(0, 2));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLanguage(currentLanguage));
    }, [currentLanguage, dispatch]);

    return (
        <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
