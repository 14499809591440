import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../actions/LanguageActions';
import { useLanguage } from '../../provider/LanguageProvider';
import { Select } from '@mantine/core';
import { getTextString } from '../../services/TextStringService';

const LanguageSelector = React.memo(() => {
    const dispatch = useDispatch();
    // @ts-ignore
    const { setCurrentLanguage } = useLanguage();
    // @ts-ignore
    const currentLanguage = useSelector(state => state.language.currentLanguage);

    useEffect(() => {
        dispatch(setLanguage(currentLanguage));
    }, [currentLanguage, dispatch]);

    const languages = {
        en: useSelector(() =>
            getTextString('text_string_language_english'),
        ),
        de: useSelector(() =>
            getTextString('text_string_language_german'),
        )
    };

    const handleChangeLanguage = (newLanguage: string) => {
        setCurrentLanguage(newLanguage);
        dispatch(setLanguage(newLanguage));
    };

    const languageOptions = Object.keys(languages).map(key => ({
        value: key,
        label: languages[key as keyof typeof languages],
    }));

    return (
        <Select
            data={languageOptions}
            value={currentLanguage}
            onChange={(language) => handleChangeLanguage(language as string)}
        />
    );
});

export default LanguageSelector;
